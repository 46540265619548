<template>
  <div class="wrap">
    <div class="The_header">
      <ul class="bg-fff">
        <li class="left">
          <router-link to>
            <i class="iconfont icon-fanhui" @click="$router.back()"></i>
          </router-link>
        </li>
        <li>{{ t("common.rechargeWay.PayOnline") }}</li>
      </ul>
    </div>
    <div class="main main_page">
      <div class="Pay_usdt">
        <a-radio-group
          v-model:value="channelCode"
          id="rechargeOnlineRadioGroup"
        >
          <a-radio
            :value="item.code"
            v-for="(item, index) in channels"
            :key="index"
          >
            <div class="Pay_usdt_box flexDiv">
              <div>
                <img
                  class="iconimg"
                  :src="
                    sysDataDictionaryWbObj?.TXHQDZ?.dataContent +
                    item.icon
                  "
                  alt=""
                />
              </div>
              <div>
                <p>
                  {{ t("common.recharge_online.channelName") }}
                  {{ item.showName }}
                </p>
                <p>
                  {{ t("common.recharge_online.min") }}
                  {{ formatMoney(item.minPaymentMoney) }}
                </p>
                <p>
                  {{ t("common.recharge_online.max") }}
                  {{ formatMoney(item.maxPaymentMoney) }}
                </p>
              </div>
            </div>
          </a-radio>
        </a-radio-group>
        
        <div class="onlineForm">
          <AForm
            layout="vertical"
            :model="formModel"
            ref="formRef"
            :rules="ruleRef"
            validateTrigger="change"
            @submit.prevent="handleRecharge"
            autocomplete="off"
          >
            <div class="Pay_usdt_box" id="input_C">
              <ul class="amountUl">
                <li class="flexLi">
                  <b>{{ CURRENCY_SYMBOL }}</b>
                  <AFormItem name="tradeMoney">
                    <AInput
                      type="number"
                      step="any"
                      v-model:value="formModel.tradeMoney"
                      :placeholder="t('profile.placeholder.rechargeAmount')"
                    />
                  </AFormItem>
                </li>
              </ul>
            </div>
            <div v-if="locale==='tur'" class="Sys_tips c-999 text-pre-line info">
              şarj etmeden önce. Lütfen doğru ödeyen adını giriniz.Platform alıcı hesap numarasını aldıktan sonra, ödemeyi 5 dakika içinde tamamlamak için doğru alacaklı adını ve kart numarasını kopyalayın! Başarılı ödemeden sonra, otomatik olarak hesaba yatırılacaktır! (Ödeme adı yanlışsa ve ödeme gecikmişse, ödeme kaçırılmayacak ve sonuçları size ait olacaktır.)
            </div>
            <div class="Sys_submit_btn withdraw">
              <button type="submit" :disabled="!validateStatus">
                {{ t("common.button.submit") }}
              </button>
            </div>
          </AForm>
        </div>
      </div>
    </div>
    <Loading :loading="loading" />
  </div>
</template>
<script>
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "vue";
import api from "@/api";
import { useI18n } from "@/lang";
import { CURRENCY_SYMBOL } from "@/utils/constant";
import { formatMoney } from "@/utils/formatter";
import { Form } from "ant-design-vue";
import Loading from "@/components/Loading.vue";
import { rechargeHtml } from "@/hooks/rechargeHtml";
import { rechargeHtmlData } from "@/hooks/rechargeHtmlData";
import { useRouter } from "vue-router";
import { sysDataDictionaryWbObj } from "@/hooks/sysDataDictionaryWbObj";
import { RE_PHONE_NUMBER } from "@/utils/constant";
const useForm = Form.useForm;
export default defineComponent({
  components: {
    Loading,
  },
  setup() {
    const { t,locale } = useI18n();
    const router = useRouter();
    const channels = ref([]);
    const channelCode = ref("");
    const loading = ref(false);
    const formModel = reactive({
      tradeMoney: "",
      type: channelCode.value,
    });
    const ruleRef = reactive({
      tradeMoney: [
        {
          required: true,
          message: t("profile.placeholder.rechargeAmount"),
        },
        {
          validator: async (_rule, val) => {
            if (!formModel.type) {
              return Promise.reject(t("error.recharge.noChannel"));
            }
            if(sysDataDictionaryWbObj.value?.ENTERINTEGER?.dataOnOff){
              const check = RE_PHONE_NUMBER.test(val);              
              if(!check){
                formModel.tradeMoney = parseInt(val)
                //return Promise.reject();
              }
            }
            if (
              +val < +currentItem?.value?.minPaymentMoney ||
              +val > +currentItem?.value?.maxPaymentMoney
            ) {
              return Promise.reject(t("error.recharge.noMatchLimit"));
            }
            if(!sysDataDictionaryWbObj.value?.ENTERINTEGER?.dataOnOff){
              if((''+val).indexOf('.')!=-1 && (''+val).slice((''+val).indexOf('.')).length>=4 ){
                return Promise.reject(t('error.recharge.decimalErr'))
              }
            }
              
            
            
          },
        },
      ],
    });
    const { validateInfos } = useForm(formModel, ruleRef);

    const validateStatus = computed(() => {
      return (
        validateInfos.tradeMoney?.validateStatus === "success" && formModel.type
      );
    });
    watch(channelCode, (newVal) => {
      console.log(newVal);
      formModel.type = newVal;
    });
    const currentItem = computed(() => {
      return channels?.value?.find((item) => {
        return item.code === channelCode.value;
      });
    });
    onMounted(async () => {
      let res = await api.getPayChannelList();
      channels.value = res?.data?.data?.onlinePayChannelVoList;
      channelCode.value = channels?.value?.[0].code
    });
    const handleRecharge = async () => {
      try {
        loading.value = true;
        let res = await api.onlinePayCreate(formModel);
        if (res?.data?.success) {
          if (res.data.data?.type === "JSON") {
            location.href = res.data.data?.data;
          } else {
            rechargeHtml.value = res.data.data?.data;
            rechargeHtmlData.value = res.data.data;
            await router.replace("/onlineRechargeHtml");
            router;
          }
        }
      } finally {
        loading.value = false;
      }
    };
    return {
      t,
      locale,
      channels,
      CURRENCY_SYMBOL,
      formatMoney,
      formModel,
      ruleRef,
      handleRecharge,
      channelCode,
      validateStatus,
      loading,
      sysDataDictionaryWbObj,
    };
  },
});
</script>
<style lang="scss">
#rechargeOnlineRadioGroup {
  box-sizing: border-box;
  padding: 0px 5px;
  width: 100vw;
  margin-top: 10px;
  .ant-radio-wrapper {
    margin-bottom: 10px;
  }
}
.onlineForm {
  #input_C {
    margin-bottom: 20px;
  }
  .amountUl {
    color: red;
    b {
      font-size: 20px;
      line-height: 38px;
    }
    .flexLi {
      display: flex;
    }
    input {
      color: red !important;
      margin-left: 18px !important;
      font-size: 18px !important;
      padding-bottom: 0px !important;
    }
  }
}
.iconimg{
  width: 40px;
  margin-right: 10px;
  margin-top: 15px;
}
.flexDiv{
  display: flex;
  box-sizing: border-box;
  padding-left: 5px;
  padding-right: 30px;
  width: 100vw;
}
.info{
  color: red !important;
  font-weight: bold;
  font-size: 16px !important;
  padding-top:0 !important;
  margin-bottom: 10px;
}
</style>
<style lang="scss" scoped>
.Pay_usdt_box{
  margin-bottom: 0;
}
</style>
